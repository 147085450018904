import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { getStructure, PopularizeDistribute, PopularizeDistributeIndex, PopularizeDistributeRead, PopularizeDistributeExit } from '@/api/ceshi_xiugai/popularize';
export default {
  data: function data() {
    return {
      id: '',
      itemsu: false,
      dataTime: true,
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      hider: true,
      num: 0,
      loading: false,
      volumeShow: false,
      title: '推广数据分配',
      extensionTitle: '新增数据分配',
      tableData: [],
      distribution: false,
      Studentleve_time: '',
      //日期
      start_time: '',
      end_time: '',
      assignment: [],
      assignments: '',
      distributionArr: [],
      pickerOptions: {
        disabledDate: function disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000;
        }
      },
      times: '',
      // 系统教程
      course: this.$route.query.course,
      prohibit: false,
      tab: 0,
      dialogWeight: false

      // 系统教程
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.GetList(this.query.page, this.query.pagesize);
    } else {}
  },
  components: {
    Head: Head
  },
  methods: {
    confirm: function confirm() {
      this.$emit('onConfirm');
    },
    cancel: function cancel() {
      this.dialogWeight = false;
      this.ruleForm.remark = '';
      this.$refs.ruleForm.resetFields();
    },
    GetList: function GetList(page, pagesize) {
      var _this = this;
      this.loading = true;
      PopularizeDistributeIndex({
        page: page,
        pagesize: pagesize,
        start_set_time: this.start_time.toString(),
        end_set_time: this.end_time.toString()
      }).then(function (respomse) {
        respomse.data.data.map(function (item) {
          var set_time = item.set_time.split(" ");
          item.set_time = set_time[0];
        });
        _this.tableData = respomse.data.data;
        _this.query.total = respomse.data.total;
        _this.loading = false;
      });
    },
    //时间
    DataTime: function DataTime() {
      console.log(this.Studentleve_time);
      if (this.Studentleve_time == null) {
        this.start_time = '';
        this.end_time = '';
      } else {
        var s_time = this.Studentleve_time[0].toString();
        this.start_time = s_time.substring(0, 10);
        var e_time = this.Studentleve_time[1].toString();
        this.end_time = e_time.substring(0, 10);
      }
    },
    addDate: function addDate() {
      var nowDate = new Date();
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
      };
      var newmonth = date.month > 10 ? date.month : '0' + date.month;
      var day = date.date > 10 ? date.date : '0' + date.date;
      var dats = date.year + '-' + newmonth + '-' + day;
      return dats;
    },
    queryList: function queryList() {
      this.query.page = 1;
      this.GetList(this.query.page, this.query.pagesize);
    },
    DataTimes: function DataTimes() {},
    DataVolume: function DataVolume(val, i) {
      if (Number(val) < 0 || val == '') {
        console.log(1111);
        this.$message({
          message: '数据量禁止小于0',
          type: 'warning'
        });
        this.distributionArr[i].set_num = '0';
      }
    },
    dealTime: function dealTime(date) {
      var day3 = new Date(date);
      day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000);
      var strMonth = day3.getMonth() + 1;
      var strDay = day3.getDate();
      if (strMonth < 10) {
        strMonth = '0' + strMonth;
      }
      if (strDay < 10) {
        strDay = '0' + strDay;
      }
      var s3 = day3.getFullYear() + '-' + strMonth + '-' + strDay;
      return s3;
    },
    newlyBuild: function newlyBuild(num) {
      var _this2 = this;
      this.extensionTitle = '新增数据量';
      this.dataTime = true;
      this.hider = true;
      this.itemsu = false;
      this.num = num;
      var time = this.dealTime(new Date());
      var adas = Number(Date.parse(time));
      this.assignment = [adas];
      getStructure().then(function (res) {
        res.data.map(function (item) {
          item.structure_id = item.id;
          item.set_num = '0';
          item.is_prohibit = 0;
        });
        _this2.distributionArr = res.data;
        _this2.distribution = true;
      });
    },
    details: function details(id) {
      var _this3 = this;
      this.extensionTitle = '数据量详情';
      this.dataTime = false;
      this.itemsu = true;
      var data = {
        id: id
      };
      PopularizeDistributeRead(data).then(function (res) {
        _this3.assignments = res.data.set_time;
        _this3.distributionArr = res.data.list;
        _this3.hider = false;
        _this3.volumeShow = true;
        _this3.distribution = true;
      });
    },
    edit: function edit(id, num) {
      var _this4 = this;
      this.id = id;
      this.num = num;
      this.dataTime = false;
      this.hider = true;
      this.itemsu = false;
      this.extensionTitle = '编辑数据量';
      PopularizeDistributeRead({
        id: id
      }).then(function (res) {
        _this4.assignments = res.data.set_time;
        _this4.distributionArr = res.data.list;
        // this.volumeShow = true
        _this4.distribution = true;
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.query.pagesize = val;
      this.GetList(this.query.page, this.query.pagesize);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      this.query.page = val;
      this.GetList(this.query.page, this.query.pagesize);
    },
    confirmation: function confirmation() {
      var _this5 = this;
      var data = {
        structure_share_str: this.distributionArr
      };
      if (this.num == 2) {
        var time = new Date(this.assignments);
        var adas = Date.parse(time);
        data.id = this.id;
        data.set_time = adas.toString().substring(0, 10);
        PopularizeDistributeExit(data).then(function (res) {
          _this5.distribution = false;
          _this5.$notify({
            title: '成功',
            message: '编辑成功',
            type: 'success'
          });
          _this5.query.page = 1;
          _this5.GetList(_this5.query.page, _this5.query.pagesize);
        });
      } else {
        data.set_time = this.assignment.toString().substring(0, 10);
        PopularizeDistribute(data).then(function (res) {
          _this5.distribution = false;
          _this5.$notify({
            title: '成功',
            message: '新增成功',
            type: 'success'
          });
          _this5.query.page = 1;
          _this5.GetList(_this5.query.page, _this5.query.pagesize);
          _this5.assignment = [];
        });
      }
    }
  }
};